<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>各分站数据统计</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="serarch">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchform" class="demo-form-inline">
                    <!-- <el-form-item label="日期">
                        <el-date-picker
                        v-model="today"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item> -->
                    <el-date-picker
                        style="margin-left:8px;"
                        v-model="value1"
                        type="daterange"
                        @change="changeDate"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                   
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="onSubmit"  >查询</el-button>
                    </el-form-item>
                    </el-form>
                </div>
                <div class="searchright"></div>
            </div>
            <div class="list" >
                <el-table
                    :data="list"
                    v-loading="loading"
                    border
                    show-summary
                    :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                    stripe
                    style="width: 100%">
                        <el-table-column
                        prop="name"
                        width="280"
                        label="分站">
                        </el-table-column>
                        <el-table-column
                        prop="memberTodayInsert"
                        label="新增企业数">
                        </el-table-column>
                        <el-table-column
                        prop="memberAll"
                        label="企业总数">
                        </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import _api from "@/api/index";
import apiUrl from "@/api/booth";
export default{
    data(){
        return{
            today:null,
            value1:[],
            list:[],
            
            loading:false,
            searchform:{
                start:null,
                end:null,
            }
        }
    },
    created(){
        this.today=this.$moment().format("YYYY-MM-DD")
        this.searchform.start=this.$moment().format("YYYY-MM-DD")
        this.searchform.end=this.$moment().format("YYYY-MM-DD")
        this.value1[0]=this.searchform.start
        this.value1[1]=this.searchform.end
        this.getList()
    },
    methods:{
        onSubmit(){
            this.getList()
        },
        changeDate(){
            if(this.value1 && this.value1.length>0){
                this.searchform.start=this.value1[0]
                this.searchform.end=this.value1[1]
            }else{
                this.searchform.start=null
                this.searchform.end=null
            }
            // this.getDetail()
        },
        getList(){
            this.loading=true
            _api.get(apiUrl.getSubstation,{...this.searchform}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.code==200){
                    this.list=res.data
                }
            })
        }
    }
}
</script>

